import { Grid, Typography } from '@mui/material';
import React from 'react';
import Crew from '../models/Crew';
import LevelChip from './LevelChip';

type Props = {
  crew: Crew;
};

const CrewName: React.FC<Props> = (props) => (
  <Grid container direction="row" alignItems="baseline">
    <Grid item />
    <Grid item xs>
      <Typography variant="body1" style={{ lineHeight: 1 }}>
        <strong>{props.crew.displayName}</strong>
        &nbsp;
        <LevelChip levelId={props.crew.levelId} />
      </Typography>
      <Typography variant="caption" color="textSecondary" style={{ lineHeight: 1 }}>
        <small>{props.crew.furigana}</small>
      </Typography>
    </Grid>
  </Grid>
);

export default CrewName;

import { Button, Checkbox, Divider, Grid, List, ListItem, ListItemText, Radio, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { v4 as uuidv4 } from 'uuid';

function isNonNull<T>(value: T | null): value is T {
  return value !== null;
}

type Props = {
  choiceType: 'single' | 'multiple';
  choices: { text: string; correct: boolean; feedback: string }[];
  readonly: boolean;
  history: Array<{ attemptedAt: Date; correct: boolean; selectedChoices: number[] }>;
  onCorrect: (selected: number[]) => void;
  onIncorrect: (selected: number[]) => void;
};

const TrainingSheetTestItemActions: React.FC<Props> = (props) => {
  const componentId = uuidv4();

  const [selectedChoices, setSelectedChoices] = useState<boolean[]>(
    props.choices.map((_, index) =>
      props.readonly
        ? props.history[props.history.length - 1]?.selectedChoices.some((i) => i === index) ?? false
        : false,
    ),
  );

  const [answered, setAnswered] = useState<boolean>(props.readonly && props.history?.length > 0);

  const handleSelect = (index: number, selected: boolean) => {
    if (props.choiceType === 'single') {
      const newChoices = props.choices.map((_, i) => (i === index ? selected : false));
      setSelectedChoices(newChoices);
    } else {
      const newChoices = selectedChoices.map((choice, i) => {
        if (i === index) {
          return selected;
        }
        return choice;
      });
      setSelectedChoices(newChoices);
    }
  };

  const handleSubmit = (selectedChoo: boolean[]) => {
    const allCorrectSelected = props.choices.every((choice, index) => choice.correct === selectedChoo[index]);

    const selectedChoicesIndex = selectedChoo.map((selected, index) => (selected ? index : null)).filter(isNonNull);
    if (allCorrectSelected) {
      props.onCorrect(selectedChoicesIndex);
    } else {
      props.onIncorrect(selectedChoicesIndex);
    }
    setAnswered(true);
  };

  const showMark = (choiceType: 'single' | 'multiple', correctAnswer: boolean, selected: boolean) => {
    const correct = '🎉 ';
    const incorrect = '❌ ';

    if (choiceType === 'single') {
      if (selected && correctAnswer) {
        return correct;
      }
      if (selected && !correctAnswer) {
        return incorrect;
      }
    } else if (choiceType === 'multiple') {
      if (selected && correctAnswer) {
        return correct;
      }

      if (selected && !correctAnswer) {
        return incorrect;
      }

      if (!selected && correctAnswer) {
        return incorrect;
      }
    }
    return '';
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={1}>
      <Grid item xs={12}>
        <List>
          {props.choices.map((choice, i) => (
            <React.Fragment key={choice.text}>
              <ListItem style={{ padding: '4px 16px' }}>
                {props.choiceType === 'single' ? (
                  <Radio
                    id={`choice-${componentId}-${i}`}
                    name={`choice-${componentId}`}
                    checked={selectedChoices[i]}
                    onChange={(evt) => handleSelect(i, evt.target.checked)}
                    disabled={props.readonly}
                    style={{ padding: '4px' }}
                  />
                ) : (
                  <Checkbox
                    id={`choice-${componentId}-${i}`}
                    name={`choice-${componentId}-${i}`}
                    checked={selectedChoices[i]}
                    onChange={(evt) => handleSelect(i, evt.target.checked)}
                    disabled={props.readonly}
                    style={{ padding: '4px' }}
                  />
                )}

                <ListItemText
                  primary={
                    <Stack direction="column" alignItems="start" justifyContent="flex-start">
                      <Typography variant="body2" component="p" width="100%">
                        <label htmlFor={`choice-${componentId}-${i}`} style={{ cursor: 'pointer', display: 'block' }}>
                          {choice.text}
                        </label>
                      </Typography>
                      {props.readonly && (
                        <Typography variant="caption" color="error" component="p">
                          {choice.feedback}
                        </Typography>
                      )}
                    </Stack>
                  }
                />

                <span>
                  {props.readonly && answered && showMark(props.choiceType, choice.correct, selectedChoices[i])}
                </span>
              </ListItem>
              <Divider component="li" />
            </React.Fragment>
          ))}
        </List>
      </Grid>
      {!props.readonly && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button
            color="primary"
            variant="contained"
            disabled={selectedChoices.filter((selected) => selected).length === 0}
            onClick={() => handleSubmit(selectedChoices)}
          >
            <Typography component="small" variant="caption" style={{ fontWeight: 'bold' }}>
              解答
            </Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default TrainingSheetTestItemActions;

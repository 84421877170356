import { useDocumentData } from 'react-firebase-hooks/firestore';
import firebase, { db } from '../utils/Firebase';
import CrewTrainingSheetResultCheck from './CrewTrainingSheetResultCheck';
import CrewTrainingSheetResultTest from './CrewTrainingSheetResultTest';

export default class CrewTrainingSheet {
  constructor(
    readonly snapshot: firebase.firestore.QueryDocumentSnapshot,
    readonly trainingSheetId: string,
    readonly crewId: string,
    readonly numberOfChecks: number,
    readonly numberOfPassedChecks: number,
    readonly numberOfAnswers: number,
    readonly number_of_correct_answers: number,
    readonly progress: number,
    readonly passed: boolean,
    readonly startedAt: Date | null,
    readonly lastAttemptedAt: Date | null,
  ) {}

  static fromData = (snapshot: firebase.firestore.QueryDocumentSnapshot, data: any) =>
    new CrewTrainingSheet(
      snapshot,
      data.training_sheet_id,
      data.crew_id,
      data.number_of_checks || 0,
      data.number_of_passed_checked || 0,
      data.number_of_answers || 0,
      data.number_of_correct_answers || 0,
      data.progress || 0,
      data.passed || false,
      data.started_at?.toDate(),
      data.last_attempted_at?.toDate(),
    );

  checksLeft = () => this.numberOfChecks - this.numberOfPassedChecks;

  static toData = (item: CrewTrainingSheet) => ({
    training_sheet_id: item.trainingSheetId,
    crew_id: item.crewId,
  });

  static collectionPath = () => 'crew_training_sheets';

  static converter = {
    toFirestore(item: CrewTrainingSheet): firebase.firestore.DocumentData {
      return CrewTrainingSheet.toData(item);
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): CrewTrainingSheet {
      const data = snapshot.data(options)!;
      return CrewTrainingSheet.fromData(snapshot, data);
    },
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  static findByTrainingSheetIdAndCrewId = (trainingSheetId: string, crewId: string) => {
    const ref = db
      .collection(CrewTrainingSheet.collectionPath())
      .withConverter(CrewTrainingSheet.converter)
      .doc(`${trainingSheetId}-${crewId}`);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDocumentData<CrewTrainingSheet>(ref);
  };

  findResultCheck = (checkId: string) => {
    const resultRef = this.snapshot.ref.collection(CrewTrainingSheetResultCheck.collectionPath()).doc(checkId);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDocumentData<CrewTrainingSheetResultCheck>(
      resultRef.withConverter(CrewTrainingSheetResultCheck.converter),
    );
  };

  findResultTest = (testId: string) => {
    const resultRef = this.snapshot.ref.collection(CrewTrainingSheetResultTest.collectionPath()).doc(testId);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDocumentData<CrewTrainingSheetResultTest>(resultRef.withConverter(CrewTrainingSheetResultTest.converter));
  };
}

import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import * as yup from 'yup';
import firebase, { db } from '../utils/Firebase';

export default class Crew {
  constructor(
    readonly snapshot: firebase.firestore.QueryDocumentSnapshot,
    readonly displayName: string,
    readonly furigana: string,
    readonly level: number,
    readonly levelId: string,
    readonly email: string,
    readonly slackId: string,
    readonly handover: string,
    readonly positiveComments: string,
    readonly picture: { name: string; path: string } | null,
    readonly workDays: number,
    readonly numberOfCooked: number,
    readonly withDriverRole: boolean,
    readonly status: string,
    readonly approved: boolean,
  ) {}

  static fromData = (snapshot: firebase.firestore.QueryDocumentSnapshot, data: any) =>
    new Crew(
      snapshot,
      data.display_name,
      data.furigana || '',
      data.level,
      data.level_id,
      data.email || '',
      data.slack_id || '',
      data.handover || '',
      data.positive_comments || '',
      data.picture ? { name: data.picture.name, path: data.picture.path } : null,
      data.work_days || 0,
      data.number_of_cooked || 0,
      data.with_driver_role || false,
      data.status,
      data.approved,
    );

  static collectionPath = () => 'crews';

  static schema = yup.object().shape({
    level: yup
      .number()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .integer(),
  });

  static converter = {
    toFirestore(item: Crew): firebase.firestore.DocumentData {
      throw new Error('not to be used');
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Crew {
      const data = snapshot.data(options)!;
      return Crew.fromData(snapshot, data);
    },
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  static filterByLevel = (level: number) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCollectionData<Crew>(
      db
        .collection(Crew.collectionPath())
        .withConverter(Crew.converter)
        .where('status', '==', 'active')
        .where('approved', '==', true)
        .where('level', '<', level),
    );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  static findByUid = (uid: string) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDocumentData<Crew>(db.collection(Crew.collectionPath()).withConverter(Crew.converter).doc(uid));

  static update = (item: Crew, data) => db.doc(item.snapshot!.ref.path).update(data);
}

import firebase from '../utils/Firebase';

export default class TrainingSheetCheck {
  constructor(
    readonly snapshot: firebase.firestore.QueryDocumentSnapshot,
    readonly question: string,
    readonly url: string | null,
    readonly number: number,
    readonly group: string,
  ) {}

  static fromData = (snapshot: firebase.firestore.QueryDocumentSnapshot, data: any) =>
    new TrainingSheetCheck(snapshot, data.question, data.url ?? null, data.number, data.group);

  static collectionPath = () => 'checks';

  static converter = {
    toFirestore(item: TrainingSheetCheck): firebase.firestore.DocumentData {
      throw new Error('not to be used');
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): TrainingSheetCheck {
      const data = snapshot.data(options)!;
      return TrainingSheetCheck.fromData(snapshot, data);
    },
  };
}

import { Box, Container, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../reusable/Header';
import TrainingSheet from '../../models/TrainingSheet';
import CrewTrainingSheet from '../../models/CrewTrainingSheet';
import CrewName from '../../reusable/CrewName';
import CrewAvatar from '../../reusable/CrewAvatar';
import TrainingSheetMessage from '../../reusable/TrainingSheetMessage';
import TrainingSheetProgress from '../../reusable/TrainingSheetProgress';
import SilentLink from '../../reusable/SilentLink';
import Crew from '../../models/Crew';
import TrainingSheetTestItems from './components/TrainingSheetTestItems';

const TrainingSheetTestPage = () => {
  const params = useParams();
  const crewUid = params.crewUid as string;
  const [crew] = Crew.findByUid(crewUid);

  const trainingSheetUid = params.trainingSheetUid as string;
  const [trainingSheet] = TrainingSheet.findByUid(trainingSheetUid);
  const [crewTrainingSheet] = CrewTrainingSheet.findByTrainingSheetIdAndCrewId(trainingSheetUid, crewUid);

  return (
    <>
      {crew && trainingSheet && crewTrainingSheet && (
        <>
          <Header title="レベルアップシート" />
          <Container>
            <Grid container spacing={4} direction="column" justifyContent="center" alignItems="stretch">
              <Grid item xs={12}>
                <Box pt={4}>
                  <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                      <Box width="30%" style={{ margin: '0 auto' }}>
                        <TrainingSheetProgress progress={crewTrainingSheet.progress} />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item xs={12}>
                        <Typography variant="body1" align="center">
                          <TrainingSheetMessage
                            passed={crewTrainingSheet.passed}
                            checksLeft={crewTrainingSheet.checksLeft()}
                          />
                        </Typography>
                      </Grid>
                      <SilentLink to={`/crews/${crewUid}`}>
                        <Grid item xs={12}>
                          <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                            <Grid item>
                              <CrewAvatar
                                crew={crew}
                                style={{
                                  width: '20px',
                                  height: '20px',
                                }}
                              />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                              <CrewName crew={crew} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </SilentLink>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs={12}>
                    <Grid container direction="column" spacing={2}>
                      <TrainingSheetTestItems
                        traineeCrew={crew}
                        trainingSheet={trainingSheet}
                        crewTrainingSheet={crewTrainingSheet}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
};

export default TrainingSheetTestPage;

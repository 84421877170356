import { ThemeProvider } from '@mui/material';
import { DialogProvider } from 'muibox';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import AppRouter from './AppRouter';
import tmcTheme from './reusable/Theme';

const App = () => (
  <ThemeProvider theme={tmcTheme}>
    <DialogProvider>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <AppRouter />
        </QueryParamProvider>
      </BrowserRouter>
    </DialogProvider>
  </ThemeProvider>
);

export default App;

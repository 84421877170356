import { Box, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC } from 'react';
import { PacmanLoader } from 'react-spinners';

type Props = { hidden?: boolean };

const OverlayLoader: FC<Props> = (props) => (
  <AnimatePresence>
    {!(props.hidden ?? false) && (
      <motion.div
        key="OverlayLoader"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Box
          pt={16}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box mb={4} height={40} style={{ marginLeft: '-40px' }}>
            <PacmanLoader color="#b3cf38" size={20} loading />
          </Box>
          <Box>
            <Typography variant="body2" align="center">
              <strong>「食をアップデート」するのだ！</strong>
            </Typography>
            <Typography variant="caption" component="p" align="center" color="textSecondary">
              読込中...
            </Typography>
          </Box>
        </Box>
      </motion.div>
    )}
  </AnimatePresence>
);

export default OverlayLoader;

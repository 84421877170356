import React, { FC } from 'react';

type Props = {
  size?: number;
  borderRadius?: number;
};

const Logo: FC<Props> = (props) => {
  const size = props.size ?? 32;
  const borderRadius = props.borderRadius ?? 4;

  return (
    <img
      alt="TOKYO MIX CURRY"
      src={`${window.location.origin}/logo192.png`}
      width={size}
      height={size}
      style={{ borderRadius: `${borderRadius}px` }}
    />
  );
};

export default Logo;

import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

type Props = {
  steps: number;
  step: number;
};

const TrainingSheetItemProgress: React.FC<Props> = (props) => (
  <div style={{ width: '100%' }}>
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={(props.step / props.steps) * 100} />
      </Box>
      <Box minWidth="2rem">
        <Typography variant="body2" color="textSecondary">
          {`${props.step} / ${props.steps}`}
        </Typography>
      </Box>
    </Box>
  </div>
);

export default TrainingSheetItemProgress;

import firebase from '../utils/Firebase';
import Crew from './Crew';

export default class CrewTrainingSheetResultTest {
  constructor(
    readonly snapshot: firebase.firestore.QueryDocumentSnapshot,
    readonly trainingSheetId: string,
    readonly crewTrainingSheetId: string,
    readonly testId: string,
    readonly history: Array<{ attemptedAt: Date; correct: boolean; selectedChoices: number[] }>,
    readonly numberOfCorrected: number,
    readonly numberOfAttempted: number,
    readonly lastAttemptedAt: Date | null,
    readonly passed: boolean,
    readonly passedAt: Date | null,
  ) {}

  static fromData = (snapshot: firebase.firestore.QueryDocumentSnapshot, data: any) =>
    new CrewTrainingSheetResultTest(
      snapshot,
      data.training_sheet_id,
      data.crew_training_sheet_id,
      data.test_id,
      data.history
        ? data.history.map((item: any) => ({
            attemptedAt: item.attempted_at.toDate(),
            correct: item.correct,
            selectedChoices: item.selected_choices,
          }))
        : [],
      data.number_of_corrected,
      data.number_of_attempted,
      data.last_attempted_at?.toDate(),
      data.passed || false,
      data.passed_at?.toDate(),
    );

  static collectionPath = () => 'result_tests';

  static converter = {
    toFirestore(item: CrewTrainingSheetResultTest): firebase.firestore.DocumentData {
      throw new Error('not to be used');
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): CrewTrainingSheetResultTest {
      const data = snapshot.data(options)!;
      return CrewTrainingSheetResultTest.fromData(snapshot, data);
    },
  };

  mark = async (correct: boolean, selectedChoices: number[], testerCrew: Crew) => {
    const now = new Date();
    await this.snapshot.ref.update({
      history: firebase.firestore.FieldValue.arrayUnion({
        attempted_at: now,
        selected_choices: selectedChoices,
        correct,
      }),
      number_of_corrected: firebase.firestore.FieldValue.increment(correct ? 1 : 0),
      number_of_attempted: firebase.firestore.FieldValue.increment(1),
      last_attempted_at: now,
    });
  };
}

import { Avatar, Button, Menu, MenuItem, Tooltip } from '@mui/material';
import { useDialog } from 'muibox';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../utils/Firebase';
import Crew from '../models/Crew';
import CrewAvatar from './CrewAvatar';

type Props = {};

const HeaderCrewBox: React.FC<Props> = (props) => {
  const [crew] = Crew.findByUid(auth.currentUser?.uid!);

  const dialog = useDialog();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMypage = () => {
    setAnchorEl(null);

    navigate('/mypage');
  };

  const handleClickUpdateRequest = () => {
    setAnchorEl(null);

    navigate('/crews/update_request');
  };

  const handleClickSignout = async () => {
    setAnchorEl(null);

    dialog
      .confirm({
        message: 'サインアウトします。よろしいですか？',
      })
      .then(() => auth.signOut());
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: 0, minWidth: 0 }}
      >
        <Tooltip title="マイページ">
          <>{crew && <CrewAvatar crew={crew} original style={{ width: '32px', height: '32px' }} />}</>
        </Tooltip>
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClickMypage}>マイページ</MenuItem>
        <MenuItem onClick={handleClickUpdateRequest}>基本情報変更申請</MenuItem>
        <MenuItem onClick={handleClickSignout}>サインアウト</MenuItem>
      </Menu>
    </>
  );
};

export default HeaderCrewBox;

import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

type Props = {
  progress: number;
};

const TrainingSheetProgress: React.FC<Props> = (props) => (
  <CircularProgressbar
    value={props.progress}
    maxValue={1}
    text={`${Math.round(props.progress * 100)}%`}
    styles={buildStyles({
      pathColor: `rgba(179, 207, 56, ${props.progress})`,
      textColor: '#000',
      trailColor: '#eeeeee',
    })}
  />
);

export default TrainingSheetProgress;

import { Alert, Card, CardActions, CardContent, CardHeader, Chip, Fade, Grid, Link, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import 'react-circular-progressbar/dist/styles.css';
import CommentIcon from '@mui/icons-material/Comment';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { useQueryParam, NumberParam, withDefault } from 'use-query-params';
import dayjs from '../../../dayjs';
import CrewTrainingSheet from '../../../models/CrewTrainingSheet';
import { auth } from '../../../utils/Firebase';
import Crew from '../../../models/Crew';
import './TrainingSheetItemStyle.css';
import TrainingSheetTestItemActions from '../../../reusable/TrainingSheetTestItemActions';
import TrainingSheetItemProgress from '../../../reusable/TrainingSheetItemProgress';

type Props = {
  testId: string;
  steps: number;
  number: number;
  questions: { text: string }[];
  choiceType: 'single' | 'multiple';
  choices: { text: string; correct: boolean; feedback: string }[];
  url: string | null;
  subheader: string;
  crewTrainingSheet: CrewTrainingSheet;
  traineeCrew: Crew;
};

const TrainingSheetTestItem: React.FC<Props> = (props) => {
  const [resultTest] = props.crewTrainingSheet.findResultTest(props.testId);

  const passed = async (selectedChoices: number[]) =>
    resultTest && props.traineeCrew && resultTest.mark(true, selectedChoices, props.traineeCrew);

  const failed = (selectedChoices: number[]) =>
    resultTest && props.traineeCrew && resultTest.mark(false, selectedChoices, props.traineeCrew);

  const [timestamp] = useQueryParam('ts', withDefault(NumberParam, null));

  const startOfThisTest = timestamp ? new Date(timestamp) : null;

  const isPassed = () => resultTest && resultTest.numberOfCorrected >= props.steps;

  const showAnswer = () => {
    if (props.crewTrainingSheet.passed) {
      return true;
    }

    if (!resultTest) {
      return false;
    }

    if (
      resultTest.passed &&
      resultTest.lastAttemptedAt &&
      startOfThisTest &&
      resultTest.lastAttemptedAt < startOfThisTest
    ) {
      return false;
    }

    return true;
  };

  const readonly = () => {
    if (auth.currentUser?.uid !== props.traineeCrew.snapshot.id) {
      return true;
    }

    if (props.crewTrainingSheet.passed) {
      return true;
    }

    if (resultTest && resultTest.lastAttemptedAt && startOfThisTest && resultTest.lastAttemptedAt > startOfThisTest) {
      // 今回回答済はreadonly
      return true;
    }

    if (resultTest && resultTest.passed) {
      // 完了済はreadonly
      return true;
    }

    return false;
  };

  const [passedForAnimation, setPassedForAnimation] = React.useState<boolean>(false);
  const [showAnswerResult, setShowAnswerResult] = React.useState<boolean>(false);

  const animateAnserResult = (pass: boolean) => {
    setPassedForAnimation(pass);
    setShowAnswerResult(true);
    setTimeout(() => {
      setShowAnswerResult(false);
    }, 2000);
  };

  return (
    <>
      {resultTest && showAnswer() && (
        <>
          <CSSTransition in={showAnswer()} timeout={500} classNames="training-sheet-item">
            <Grid item xs={12}>
              <Card
                raised={!isPassed()}
                sx={{
                  position: 'relative',
                  border: resultTest.numberOfCorrected === undefined ? 0 : 2,
                  borderColor: resultTest.numberOfCorrected === props.steps ? 'primary.main' : 'error.main',
                }}
              >
                <CardHeader
                  title={
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Typography
                          variant="body1"
                          color={isPassed() ? 'textSecondary' : 'textPrimary'}
                          style={{
                            fontWeight: isPassed() ? 'normal' : 'bold',
                            verticalAlign: 'bottom',
                          }}
                        >
                          Q{props.number}
                        </Typography>
                      </Grid>
                      {props.url && (
                        <Grid item xs="auto">
                          <Typography variant="caption">
                            <Link
                              color="inherit"
                              href={props.url!}
                              underline="always"
                              style={{ display: 'flex', alignItems: 'center' }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <InfoIcon style={{ fontSize: '1rem' }} />
                            </Link>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  }
                  action={
                    <>
                      <Grid item xs container direction="column" alignItems="stretch" spacing={1}>
                        <Grid item xs={12}>
                          {resultTest.numberOfCorrected !== props.steps ? (
                            props.steps > 1 && (
                              <TrainingSheetItemProgress step={resultTest.numberOfCorrected ?? 0} steps={props.steps} />
                            )
                          ) : (
                            <Typography variant="body1" align="center">
                              <strong>合格🙆</strong>
                            </Typography>
                          )}
                        </Grid>
                        {resultTest.history.length > 0 && (
                          <Grid item xs container justifyContent="center" alignItems="center" spacing={1}>
                            {resultTest.history.map((h, i) => (
                              <Grid item key={`${h.attemptedAt}`}>
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  label={dayjs(h.attemptedAt).tz('Asia/Tokyo').format('MM/DD')}
                                  icon={h.correct ? <DoneIcon /> : <ClearIcon />}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        )}
                      </Grid>
                    </>
                  }
                  subheader={props.subheader}
                  subheaderTypographyProps={{ variant: 'caption' }}
                  style={{ paddingBottom: '0px' }}
                />
                <CardContent style={{ paddingBottom: '0px' }}>
                  <Alert icon={<CommentIcon fontSize="inherit" />} severity="warning" style={{ padding: '6px 8px' }}>
                    {props.questions.map((question) => {
                      return question.text.match(/^https:\/\//) ? (
                        <img style={{ width: '100%' }} src={question.text} alt={`Q${props.number}`} />
                      ) : (
                        <div>{question.text}</div>
                      );
                    })}
                  </Alert>
                </CardContent>
                <CardActions disableSpacing style={{ paddingTop: '0px' }}>
                  <TrainingSheetTestItemActions
                    choiceType={props.choiceType}
                    choices={props.choices}
                    history={resultTest.history}
                    readonly={readonly()}
                    onCorrect={(selectedChoices: number[]) => {
                      animateAnserResult(true);
                      passed(selectedChoices);
                    }}
                    onIncorrect={(selectedChoices: number[]) => {
                      animateAnserResult(false);
                      failed(selectedChoices);
                    }}
                  />
                </CardActions>
                <Fade in={showAnswerResult}>
                  <div
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      <Typography variant="h4">
                        <strong>{passedForAnimation ? '正解!🎉' : '残念...❌'}</strong>
                      </Typography>
                    </div>
                  </div>
                </Fade>
              </Card>
            </Grid>
          </CSSTransition>
        </>
      )}
    </>
  );
};

export default TrainingSheetTestItem;

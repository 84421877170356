import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import firebase, { db } from '../utils/Firebase';

export default class CrewLevel {
  constructor(
    readonly snapshot: firebase.firestore.QueryDocumentSnapshot,
    readonly name: string,
    readonly level: number,
    readonly order: string,
  ) {}

  static fromData = (snapshot: firebase.firestore.QueryDocumentSnapshot, data: any) =>
    new CrewLevel(snapshot, data.name, data.level, data.order);

  static collectionPath = () => 'crew_levels';

  static converter = {
    toFirestore(item: CrewLevel): firebase.firestore.DocumentData {
      throw new Error('not to be used');
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): CrewLevel {
      const data = snapshot.data(options)!;
      return CrewLevel.fromData(snapshot, data);
    },
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  static all = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCollectionData<CrewLevel>(
      db.collection(CrewLevel.collectionPath()).withConverter(CrewLevel.converter).orderBy('order', 'asc'),
    );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  static findByUid = (uid: string) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDocumentData<CrewLevel>(db.collection(CrewLevel.collectionPath()).withConverter(CrewLevel.converter).doc(uid));

  static findFirstLevel = async () => {
    const query = db
      .collection(CrewLevel.collectionPath())
      .withConverter(CrewLevel.converter)
      .orderBy('level', 'asc')
      .limit(1);
    const snapshot = await query.get();

    if (snapshot.empty) {
      throw new Error('No data found!');
    }
    const data = snapshot.docs[0].data();
    return CrewLevel.fromData(snapshot.docs[0], data);
  };
}

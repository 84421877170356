import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import Avatar from '@mui/material/Avatar';
import Crew from '../models/Crew';

function CrewAvatar(props: {
  crew: Crew;
  original?: boolean;
  style: { width?: string; height?: string; margin?: string };
}) {
  const [pictureUrl, setPictureUrl] = useState('');

  useEffect(() => {
    if (props.crew.picture) {
      const fileName = props.original
        ? `${props.crew.picture.path}/${props.crew.picture.name}`
        : `${props.crew.picture.path}/thumb@1080_${props.crew.picture.name}`;
      firebase
        .storage()
        .ref(fileName)
        .getDownloadURL()
        .then((url) => {
          setPictureUrl(url);
        });
    } else {
      setPictureUrl('https://tokyomixcurry.com/mobile/images/app/notifications/default.jpg');
    }
  }, [props.crew.picture, props.original]);

  return <Avatar alt={props.crew.displayName} src={pictureUrl} style={props.style} />;
}

export default CrewAvatar;

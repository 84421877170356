import firebase from '../utils/Firebase';

export default class TrainingSheetTest {
  constructor(
    readonly snapshot: firebase.firestore.QueryDocumentSnapshot,
    readonly questions: { text: string }[],
    readonly choiceType: 'single' | 'multiple',
    readonly choices: { text: string; correct: boolean; feedback: string }[],
    readonly url: string | null,
    readonly number: number,
    readonly group: string,
  ) {}

  static fromData = (snapshot: firebase.firestore.QueryDocumentSnapshot, data: any) =>
    new TrainingSheetTest(
      snapshot,
      data.questions,
      data.choice_type,
      data.choices,
      data.url ?? null,
      data.number,
      data.group,
    );

  static collectionPath = () => 'tests';

  static converter = {
    toFirestore(item: TrainingSheetTest): firebase.firestore.DocumentData {
      throw new Error('not to be used');
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): TrainingSheetTest {
      const data = snapshot.data(options)!;
      return TrainingSheetTest.fromData(snapshot, data);
    },
  };
}

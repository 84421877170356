import firebase from '../utils/Firebase';
import Crew from './Crew';

export default class CrewTrainingSheetResultCheck {
  constructor(
    readonly snapshot: firebase.firestore.QueryDocumentSnapshot,
    readonly trainingSheetId: string,
    readonly crewTrainingSheetId: string,
    readonly checkId: string,
    readonly history: Array<{ attemptedAt: Date; checkerCrewId: string; correct: boolean }>,
    readonly numberOfCorrected: number,
    readonly numberOfAttempted: number,
    readonly lastAttemptedAt: Date | null,
    readonly passed: boolean,
    readonly passedAt: Date | null,
  ) {}

  static fromData = (snapshot: firebase.firestore.QueryDocumentSnapshot, data: any) =>
    new CrewTrainingSheetResultCheck(
      snapshot,
      data.training_sheet_id,
      data.crew_training_sheet_id,
      data.check_id,
      data.history
        ? data.history.map((item: any) => ({
            attemptedAt: item.attempted_at.toDate(),
            checkerCrewId: item.checker_crew_id,
            correct: item.correct,
          }))
        : [],
      data.number_of_corrected,
      data.number_of_attempted,
      data.last_attempted_at?.toDate(),
      data.passed || false,
      data.passed_at?.toDate(),
    );

  static collectionPath = () => 'result_checks';

  static converter = {
    toFirestore(item: CrewTrainingSheetResultCheck): firebase.firestore.DocumentData {
      throw new Error('not to be used');
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): CrewTrainingSheetResultCheck {
      const data = snapshot.data(options)!;
      return CrewTrainingSheetResultCheck.fromData(snapshot, data);
    },
  };

  mark = async (correct: boolean, checkerCrew: Crew) => {
    const now = new Date();
    await this.snapshot.ref.update({
      history: firebase.firestore.FieldValue.arrayUnion({
        attempted_at: now,
        checker_crew_id: checkerCrew.snapshot.id,
        correct,
      }),
      number_of_corrected: firebase.firestore.FieldValue.increment(correct ? 1 : 0),
      number_of_attempted: firebase.firestore.FieldValue.increment(1),
      last_attempted_at: now,
    });
  };
}

import React from 'react';
import CrewLevel from '../models/CrewLevel';

type Props = {
  levelId: string;
};

const LevelChip: React.FC<Props> = (props) => {
  const [level] = CrewLevel.findByUid(props.levelId);

  return (
    <>
      {level && (
        <span
          style={{
            background: '#170000',
            color: '#fff',
            padding: '0px 2px',
            borderRadius: '4px',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '12px',
          }}
        >
          {level.name}
        </span>
      )}
    </>
  );
};

export default LevelChip;

/* eslint-disable react/jsx-props-no-spreading */
import React, { lazy, Suspense, useState, useEffect, useContext } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import LoadingIndicator from './reusable/LoadingIndicator';
import { auth, db } from './utils/Firebase';
import OverlayLoader from './reusable/OverlayLoader';
import Crew from './models/Crew';
import RoleContext from './RoleContext';
import Role from './Role';
import TrainingSheetTestPage from './views/training-sheet/TrainingSheetTestPage';

const SignIn = lazy(() => import('./pages/SignIn'));
const SignOut = lazy(() => import('./pages/SignOut'));
const MypagePage = lazy(() => import('./views/mypage/MypagePage'));
const CrewPage = lazy(() => import('./views/crew/CrewPage'));
const EditCrewPage = lazy(() => import('./views/crew/EditCrewPage'));
const ApproveCrewPage = lazy(() => import('./views/crew/ApproveCrewPage'));
const TrainingSheetPage = lazy(() => import('./views/training-sheet/TrainingSheetPage'));
const CrewUpdateRequestPage = lazy(() => import('./views/crew/CrewUpdateRequestPage'));

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <OverlayLoader />;
  }

  return user ? children : <Navigate to="/signin" replace />;
};

const RequireApproved = ({ children }: { children: JSX.Element }) => {
  const [currentCrew, loading] = Crew.findByUid(auth.currentUser?.uid!);

  if (loading) {
    return <OverlayLoader />;
  }

  if (currentCrew) {
    if (!currentCrew.approved) {
      return <Navigate to="/crews/update_request" />;
    }
  } else {
    return <Navigate to="/signout" />;
  }

  return <>{children}</>;
};

const RequireCrewAdmin = ({ children }: { children: JSX.Element }) => {
  const role = useContext(RoleContext);
  if (!role.isCrewAdmin()) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

const RequireCrewTraining = ({ children }: { children: JSX.Element }) => {
  const role = useContext(RoleContext);
  if (!role.isCrewTraining()) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

const AppRouter = () => {
  const [user, loading] = useAuthState(auth);
  const [role, setRole] = useState<Role>();

  useEffect(() => {
    if (user) {
      if (user && user.providerData[0]?.providerId === 'google.com' && user.email?.match(/@tokyomixcurry.com$/)) {
        setRole(new Role(['super-admin']));
      } else {
        try {
          const unregisterSlotsObserver = db
            .collection('admins')
            .doc(user.uid)
            .onSnapshot(
              (admin) => {
                if (admin.exists) {
                  const { roles } = admin.data()!;
                  if (roles && roles.length > 0) {
                    setRole(new Role(admin.data()!.roles));
                  } else {
                    setRole(new Role([]));
                  }
                }
              },
              (err) => {
                console.log(err);
                setRole(new Role([]));
              },
            );

          return () => {
            unregisterSlotsObserver();
          };
        } catch (e) {
          setRole(new Role([]));
        }
      }
    } else {
      setRole(new Role([]));
    }
    return () => {};
  }, [user]);

  return (
    <Suspense fallback={<LoadingIndicator />}>
      {role && (
        <RoleContext.Provider value={role}>
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signout" element={<SignOut />} />
            <Route path="/crews/:crewUid/training_sheets/:trainingSheetUid/check" element={<TrainingSheetPage />} />
            <Route path="/crews/:crewUid/training_sheets/:trainingSheetUid/test" element={<TrainingSheetTestPage />} />
            <Route
              path="/crews/:crewUid/edit"
              element={
                <RequireAuth>
                  <RequireCrewTraining>
                    <EditCrewPage />
                  </RequireCrewTraining>
                </RequireAuth>
              }
            />
            <Route
              path="/crews/:crewUid/approve"
              element={
                <RequireAuth>
                  <RequireCrewAdmin>
                    <ApproveCrewPage />
                  </RequireCrewAdmin>
                </RequireAuth>
              }
            />
            <Route
              path="/crews/:crewUid"
              element={
                <RequireAuth>
                  <RequireApproved>
                    <CrewPage />
                  </RequireApproved>
                </RequireAuth>
              }
            />
            <Route
              path="/crews/update_request"
              element={
                <RequireAuth>
                  <CrewUpdateRequestPage />
                </RequireAuth>
              }
            />
            <Route
              path="/mypage"
              element={
                <RequireAuth>
                  <RequireApproved>
                    <MypagePage />
                  </RequireApproved>
                </RequireAuth>
              }
            />
            <Route path="/" element={<Navigate to="/mypage" replace />} />
          </Routes>
        </RoleContext.Provider>
      )}
    </Suspense>
  );
};

export default AppRouter;

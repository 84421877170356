import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import firebase, { db } from '../utils/Firebase';
import TrainingSheetCheck from './TrainingSheetCheck';
import TrainingSheetTest from './TrainingSheetTest';

export default class TrainingSheet {
  constructor(
    readonly snapshot: firebase.firestore.QueryDocumentSnapshot,
    readonly name: string,
    readonly type: 'check' | 'test',
    readonly level: number,
    readonly order: number,
    readonly requiredCorrectAnswers: number,
  ) {}

  static fromData = (snapshot: firebase.firestore.QueryDocumentSnapshot, data: any) =>
    new TrainingSheet(snapshot, data.name, data.type, data.level, data.order, data.required_correct_answers);

  static collectionPath = () => 'training_sheets';

  static converter = {
    toFirestore(item: TrainingSheet): firebase.firestore.DocumentData {
      throw new Error('not to be used');
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): TrainingSheet {
      const data = snapshot.data(options)!;
      return TrainingSheet.fromData(snapshot, data);
    },
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  static getAll = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCollectionData<TrainingSheet>(
      db
        .collection(TrainingSheet.collectionPath())
        .withConverter(TrainingSheet.converter)
        .where('valid', '==', true)
        .orderBy('order'),
    );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  static getByLevel = (level: number) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCollectionData<TrainingSheet>(
      db
        .collection(TrainingSheet.collectionPath())
        .withConverter(TrainingSheet.converter)
        .where('valid', '==', true)
        .where('level', '==', level),
    );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  static findByUid = (uid: string) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDocumentData<TrainingSheet>(
      db.collection(TrainingSheet.collectionPath()).withConverter(TrainingSheet.converter).doc(uid),
    );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  checks = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCollectionData<TrainingSheetCheck>(
      this.snapshot.ref
        .collection(TrainingSheetCheck.collectionPath())
        .withConverter(TrainingSheetCheck.converter)
        .where('valid', '==', true)
        .orderBy('number'),
    );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  tests = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCollectionData<TrainingSheetTest>(
      this.snapshot.ref
        .collection(TrainingSheetTest.collectionPath())
        .withConverter(TrainingSheetTest.converter)
        .where('valid', '==', true)
        .orderBy('number'),
    );
}

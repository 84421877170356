import React from 'react';

type Props = {
  passed: boolean;
  checksLeft: number;
};

const TrainingSheetMessage: React.FC<Props> = (props) => (
  <>
    {props.passed ? (
      <>達成しました🎉</>
    ) : (
      <>
        達成まであと<strong>{props.checksLeft}</strong>問💪
      </>
    )}
  </>
);

export default TrainingSheetMessage;

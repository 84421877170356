import { createTheme } from '@mui/material/styles';

const tmcTheme = createTheme({
  palette: {
    primary: {
      main: '#B3CF38',
    },
    background: {
      default: '#ffffff',
    },
    divider: 'rgba(60, 60, 67, 0.3)',
  },
  typography: {
    fontSize: 14,
    button: {
      fontStyle: 'bold',
      fontWeight: 'bold',
      textTransform: 'none',
    },
  },
});

export default tmcTheme;

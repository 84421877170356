import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import CrewTrainingSheet from '../../../models/CrewTrainingSheet';
import TrainingSheet from '../../../models/TrainingSheet';
import TrainingSheetTestItem from './TrainingSheetTestItem';
import Crew from '../../../models/Crew';

type Props = {
  trainingSheet: TrainingSheet;
  crewTrainingSheet: CrewTrainingSheet;
  traineeCrew: Crew;
};

const TrainingSheetTestItems: React.FC<Props> = (props) => {
  const [tests] = props.trainingSheet.tests();

  return (
    <>
      {tests &&
        tests.map((test) => (
          <TrainingSheetTestItem
            key={test.snapshot.id}
            testId={test.snapshot.id}
            number={test.number}
            questions={test.questions}
            choiceType={test.choiceType}
            choices={test.choices}
            url={test.url}
            subheader={test.group}
            steps={props.trainingSheet.requiredCorrectAnswers}
            crewTrainingSheet={props.crewTrainingSheet}
            traineeCrew={props.traineeCrew}
          />
        ))}
    </>
  );
};

export default TrainingSheetTestItems;

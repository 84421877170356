import { AppBar, Grid, Toolbar, Typography } from '@mui/material';
import React from 'react';
import Crew from '../models/Crew';
import { auth } from '../utils/Firebase';
import HeaderCrewBox from './HeaderCrewBox';
import Logo from './Logo';
import SilentLink from './SilentLink';

type Props = {
  title: string;
};

const Header: React.FC<Props> = (props) => {
  const [crew] = Crew.findByUid(auth.currentUser?.uid!);

  return (
    <>
      {crew && (
        <>
          <AppBar position="fixed" style={{ backgroundColor: '#fff' }} elevation={2}>
            <Toolbar>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <SilentLink to="/">
                    <Logo size={24} />
                  </SilentLink>
                </Grid>
                <Grid item xs>
                  <Typography variant="h6">{props.title}</Typography>
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <HeaderCrewBox />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Toolbar />
        </>
      )}
    </>
  );
};

export default Header;
